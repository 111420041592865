export let translationRu = {
  connection: 'Подключение',
  tariff: 'Тарифы',
  workingMethod: 'Метод работы',
  enter: 'Войти',
  registration: 'Регистрация',
  systemElectronicDocument: 'Система электронного документооборота',
  kiberInformation:
    'Kiber - это быстрый, простой и доступный сервис электронного документооборота счётов-фактур и других бухгалтерских документов. Мы рады оказать максимально качественную услугу и упростить Вашу работу. Ваша критика в наш адрес - это Ваша забота о нас!',
  kiberMethod: 'Метод работы в Kiber',
  kiberSystem: 'С системой Kiber',
  systemInformation:
    'Вы легко можете освоить работу с ним, т.к. она удобная для зрительного восприятия и понятный интерфейс.',
  kiberQ_A: 'Kiber — вопросы и ответы',
  q_aInformation:
    'В случае возникновения вопросов можете дополнительно получить в Телеграм-группе «Kiber — вопросы и ответы».',
  IndividualConsultations: 'Индивидуальные консультации',
  IndividualConsultationsInformation:
    'А для пользователей тарифа `Платный` доступны индивидуальные консультации наших специалистов.',
  shareDocumentsPhone: 'Обменивайтесь документами с телефона',
  shareDocumentsPhoneInformation:
    'Скоро Вы можете обмениваться электронными документами с помощью мобильного приложения Kiber. Это сделает Ваш бизнес не только более простым, но и более приятным и прекрасным.',
  loginWorld: 'Вход из любой точки мира!',
  downloadMobile:
    'Загрузите мобильное приложение и избавьтесь от бумажной пряжи!',
  electronicDocument:
    'Программное обеспечение электронного документооборота «KIBER.UZ» (версия 1.0)',
  system: 'Система',
  support: 'Служба поддержки',
  faq: 'Частые вопросы',
  UsersManual: 'Руководство пользователя',
  feedback: 'Обратная связь',
  productAdded: 'Продукт добавлен',
  followUs: 'Подписывайтесь на нас',
  servicesLicensed: 'Услуги лицензированы, товары сертифицированы',
  loginSystem: 'Вход в систему',
  chooseKey: 'Выберите ключ',
  whithKey: 'С помощью ключа',
  withPassword: 'С помощью пароля',
  personalArea: 'Личный кабинет',
  Statistics: 'Статистика',
  userData: 'Данные пользователя',
  notification: 'Уведомление',
  editFoto: ' Изменить фото',
  userPosition: 'Позиция пользователя',
  editData: 'Редактировать данные',
  seve: 'Сохранить',
  tin: 'ИНН',
  tinTwo: 'Инн',
  position: 'Позиция',
  bankAccount: 'Банковский счет',
  mfi: 'МФО',
  phoneNumber: 'Номер телефона',
  mail: 'Почта',
  firstLastName: 'Имя и фамилия',
  login: 'Логин',
  parol: 'Пароль',
  editingProfilePhoto: 'Редактирование фото профиля',
  chooseAnother: 'Выбрать другое',
  downloadPDF: 'Скачать PDF',
  accept: 'Принять',
  cansel: 'Отменить',
  decline: 'Отказать',
  myGoods: 'Мои товары (услуги)',
  enterNameCode: 'Введите название или код товара (услуги)',
  add: 'Добавить',
  classifierCode: 'Код классификатора',
  classifierName: 'Название классификатора',
  saved: 'Сохранённые',
  documentType: 'Тип документа',
  dataCreation: 'Дата создания',
  outgoingDocuments: 'Исходящие документы',
  incomingDocuments: 'Входящие документы',
  powerDocuments: 'Доверенности документы',
  invoices: 'Счета-фактуры',
  acts: 'Акты',

  standard: 'Стандартный',
  additional: 'Дополнительный',
  additionalExp: 'Дополнительный (возмещение затрат)',
  compensationExp: 'Возмещение расходов',
  noPayment: 'Без оплаты',
  corrected: 'Исправленный',
  correctedExp: 'Исправленный (возмещение затрат)',
  ttu: 'Путевой лист',
  checkedActs: 'Акт сверки',

  ttn: 'ТТН (новый)',

  phisFace: 'На физ. лицо',
  eksportServiceFrom: 'Экспорт услуг (за территорию Республики Узбекистан)',
  importService: 'На импорт',
  financeService: 'Финансовые услуги',
  belowMarket: 'Реализация ниже рыночной стоимости',
  belowTax: 'Реализация ниже таможенной стоимости',
  eskportServiceIn: 'Экспорт услуг (на территории Республики Узбекистан)',
  realizationGov: 'Реализация, связанная с гос. секретом',
  otherIncome: 'Другие доходы',
  recycleAksiz: 'Сдача акцизных товаров на переработку',
  reuseAksiz: 'Свободное использование акцизных товаров',
  priceDifferenceAksiz:
    'Разница акзциного товара между ценой и себестоимостью ',
  destroyAksiz: 'Потеря акцизных товаров',

  newTransportNo: 'Новая ТТН',
  transportNo: 'ТТН №',
  date: 'дата',
  carriageType: 'Тип перевозки',
  contractNo: 'К договору №',
  roadNo: 'К путевому листу №',
  transportType: 'Тип транспорта',
  fromCtoC: 'со склада на склад',
  fromStoB: 'от продавца к покупателю',
  car: 'Перевозка автомобильным транспортом',
  train: 'Перевозка по железной дороге',
  tarifs: 'Тарифы',
  chooseTarif: 'Выбрать Тариф',
  docActive: ' Свидетельство активно',
  docNotActive: ' Свидетельство не активно',
  docTempNotActive: ' Свидетельство временно не активно',
  type: 'модель',
  carGovNo: 'Гос. номер автомобиля',
  trailer: 'Прицеп',
  semiTrailer: 'Полуприцеп',
  trailerType: 'Тип прицепа',
  govNo: 'Гос. номер',
  shiftForm: 'Сводная на всю смену',
  driverName: 'ФИО водителя',
  roadList: 'Путевой Лист',
  transport: 'Транспорт',

  dealer: 'Диллер',
  customer: 'Заказчик',
  specialNotes: 'Особые заметки',

  sender: 'Грузоотправитель',
  firstAddressTo: 'Пункт погрузки 1',
  secondAddressTo: 'Пункт погрузки 2',
  otherAddress: 'Переадресовка',
  submitted: 'Сдал',
  getter: 'Грузополучатель',
  firstAddressDrop: 'Пункт разгрузки 1',
  secondAddressDrop: 'Пункт разгрузки 2',
  driver: 'Сдал вод./эксп',
  recieved: 'Принял',
  distance: 'Расстояние перевозок',
  overall: 'всего',
  inCity: 'в городе',
  filial: 'Филиал',

  waybill: 'Путевой лист',

  contract: 'Договора',
  empowerment: 'Доверенности',
  filter: 'Фильтр',
  document: 'Документ',
  documents: 'Документы',
  productsWithoutVat: 'Товар без НДС',
  productsWithZeroVat: 'Товар/Сервис (с 0% ставкой НДС)',
  companiesSeller: 'Учреждения продающие товар без НДС или с 0% ставкой НДС',
  companiesBuyer: 'Учреждения покупающие товар без НДС или с 0% ставкой НДС',
  productsNotIncluded: 'Не принимаемые в зачет товары',
  compensations: 'Возмещаемые товары',
  productId: 'ID',
  catalogCode: 'MXIK',
  catalogName: 'Наименования по классификатору',
  lgotaId: 'Привилегия ID (старая)',
  newLgotaId: 'Привилегия ID (новая)',
  lgotaName: 'Привилегия',
  tasnifName: 'Название категории',
  tasnifCode: 'MXIK',
  nameOfCompany: 'Название Фирмы',
  dateStart: 'Начало Привилегии',
  dateEnd: 'Конец Привилегии',
  tinCompany: 'ИНН Фирмы',
  number: 'Номер',
  sum: 'Сумма',
  treaty: 'Договор',
  clear: 'Очистить',
  apply: 'Применить',
  status: 'Состояние',
  counterparty: 'Контрагент',
  numberData: 'Номер и дата',
  subscribe: 'Подписать',
  createContract: 'Создать новый контракт',
  tinPinfl: 'ИНН/ПИНФЛ',
  LotNo: 'Лот №',
  name: 'Название',
  fizTin: 'ПИНФЛ физ. лица',
  fio: 'ФИО',
  mobile: 'Номер мобильного телефона',
  workPhone: 'Телефон',
  address: 'Адрес',
  bankId: 'МФО, SWIFT и др.',
  account: 'Расчётный счёт',
  oked: 'ОКЭД',
  addProduct: 'Добавить новый товар',
  total: 'Итого',
  only: 'Всего',
  exciseSum: 'Акциз, Сумма',
  exciseRate: 'Акциз, ставка',
  vatSum: ' НДС, Сумма',
  vat: 'НДС',
  deliveryCost: ' Стоимость поставки',
  price: 'Цена',
  qty: 'Кол-во',
  unitMeasurements: 'Ед. измер.',
  barCode: 'Штрих код товара/услуги',
  productIdentification: 'Идентификационный код товара (услуги)',
  nameGoods: 'Наименование товара (услуги)',
  no: 'No',
  content: 'Содержание',
  delete: 'Удалить',
  oldInvoice: ' Данные старой счет фактуры',
  idInvoice: 'ID старой счет фактуры',
  oldInvoiceNumber: 'Номер старой счет фактуры',
  oldInvoiceDate: 'Дата старой счет фактуры',
  invoiceType: 'Тип фактуры',
  unilateralInvoice: 'Односторонняя счёт-фактура',
  unilateralDocument: 'Тип одностороннего документа',
  exciseInvoice: 'Счёт-фактура с акцизным налогом',
  empowermentNomber: 'Номер доверенности',
  empowermentData: 'Дата доверенности',
  tinFace: 'ИНН отв. лица',
  fioFace: 'Ф.И.О. отв. лица',
  documentData: ' Данные документа',
  denomination: 'Наименование',
  contractNumber: 'Номер контракта',
  placeConclusion: 'Место заключения',
  dataConclusion: 'Дата заключения',
  validUntil: 'Действителен до',
  partnerDate: 'Данные партнёра',
  removePartner: 'Удалить Партнёра',
  addPartner: 'Добавить Партнёра',
  createAct: 'Создать новый акт выполненных работ',
  createTtn: 'Создать новый ТТН',
  documentText: 'Техт документа',
  textAct: 'Текст акта',
  yourData: 'Ваши данные',
  company: 'Компания',
  addNewProduct: ' Добавить новый товар',
  withoutVat: 'Без НДС',
  act: 'Акт №',
  documentDate: 'Дата документа',
  contractDate: 'Дата контракта',
  portnerCompany: ' Компания партнёра',
  outgoing: 'Исходящие',
  inbox: 'Входящие',
  confidant: 'Доверенное лицо',
  listDocumnt: 'Список документов',
  createNewDocument: 'Создать новый документ',
  factura: 'Счет фактура',
  ACT: 'АКТ',
  empowermentTwo: 'Доверенность',
  setting: 'Настройки',
  kiberVersion:
    'Программное обеспечение системы электронного документооборота «KIBER.UZ» ',
  version: '(версия 1.0)',
  companyName: 'ООО `TESLAPRO`, Республика Узбекистан',
  exit: 'Выход',
  startTime: 'Время начала',
  endTime: 'Время окончания',
  canceledDocumnet: 'Вы отменили этот документ',
  acceptedDocument: 'Вы приняли этот документ',
  rejectedDocument: 'Вы отклонили этот документ',
  savedDacument: 'Вы сохранили этот документ',
  texture: 'Фактура',
  download: ' Скачать PDF',
  certificateCompletion: 'Акт выполненных  работ',
  invoice: 'Счет-фактура',
  rejectionDocument: 'Подтвердить отклонение документа?',
  receiptDocument: 'Подтверждаете получение документа?',
  enterReason: 'Введите причину',
  cancellation: 'Отмена',
  confirmation: 'Подтверждение',
  notRegistered: 'Вы не зарегистрированы в системе',
  dataIncorrectly: 'Данные введены неверно',
  documentSuccessfully: 'Документ успешно создан',
  language: 'Язык',
  languages: 'Ру',
  uniqueTariffs: 'Уникальные тарифы от Kiber:',
  dock: '/ док.',
  som: 'сум',
  sendUp: 'Отправка до 1 000 документов в месяц',
  ReceivingDocument: 'Получение документов бесплатно',
  buy: 'Купить',
  iAgree: 'Я согласен с условиями',
  publicOffer: 'публичной оферты',
  register: 'Регистрироваться',
  noDocument: 'Нет документов',
  header: 'Заголовок',
  text: 'Текст',
  createPower: 'Создать новую доверенность',
  dateIssue: 'Дата выдачи',
  director: 'Директор',
  accountant: 'Бухгалтер',
  jopTitle: 'Должность',
  seriesPassword: 'Серия и номер паспорта',
  issueBy: 'Кем выдан',
  createInvoice: 'Создать новую счёт-фактуру',
  invoiceNumber: 'Номер счёта-фактуры',
  vatRegisttation: 'Регистрационный код плательщика НДС',
  goodReleased: 'Товар отпустил',
  enterPassword: 'Введите пароль для ключа',
  free: 'Бесплатно',
  freePlan: 'Тариф бесплатный первые 2 месяца',
  appliesAll:
    'Применяется ко всем отправленным документам за текущий месяц при более чем 100 исходящих документов',
  allDocument: 'Тарифицируются и учитываются все исходящие документы',
  documentSending:
    'При отправке документов менее 100 за текущий месяц , тарифавтоматически меняется на "Базовый тариф"',
  sendPurchase:
    'Отправляйте запросы на покупку и торговые предложения в неограниченном количестве',
  getAccess:
    'Получите доступ к базе поставщиков и клиентов из более чем 250 тыс. участников экосистемы Дидокс',
  changePassword: 'Изменить пароль',
  close: 'Закрывать',
  packageCode: 'Код пакета',
  back: 'Назад',
  privilege: 'Привилегия',
};
