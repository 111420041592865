import './styles.scss';
type propsType = {
  id?: string;
  date?: string;
  title?: string;
  identifikatsiya?: string;
  operator?: string;
  type?: string;
};
const FacturaItem = (props: propsType) => {
  const date = new Date();
  return (
    <div
      className="factura_item"
      style={{
        border:
          props.type === 'sucses'
            ? '3px solid #4baa47'
            : props.type === 'canceled'
            ? '3px solid #d9534f'
            : '3px solid #9d9b9b',
        background:
          props.type === 'sucses'
            ? '#d0eef4'
            : props.type === 'canceled'
            ? '#f8d7da'
            : '#f3f3f3',
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 10px',
        }}>
        <p style={{ fontSize: '9px' }}>{props.id}</p>
        <div style={{ display: 'flex', fontSize: '11px' }}>{props.date}</div>
      </div>
      <div
        className="title"
        style={{
          color:
            props.type === 'sucses'
              ? '#27a123'
              : props.type === 'canceled'
              ? '#d9534f'
              : '#9d9b9b',
        }}>
        <h1>{props.title}</h1>
      </div>

      <div className="identifikatsiya">
        <p>{props.identifikatsiya}</p>
      </div>
      <div>
        <p>
          <b>operator:</b>
          {props.operator}
        </p>
      </div>
    </div>
  );
};

export default FacturaItem;
